<template>
  <div>
    <div id="pdf-content" style="font-size: 3mm">
      <div style="page-break-after: always; padding: 1mm" id="content">
        <table style="width: 100%; position: relative; margin-bottom: 5mm">
          <tr>
            <td>HÓA ĐƠN BÁN HÀNG</td>
            <td rowspan="3" class="text-right">
              <img
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABkCAYAAABw4pVUAAAAAklEQVR4AewaftIAAAL9SURBVO3BQW4dORTAQFL4978yJ8uHLGQ02naEgaoE4hdV7KhMFTsq36liUvlNi+soi+soi+soH/5S8Z1UdlSmih2VJyq+U8V3UpkW11EW11EW11E+fEHliYonKiaVnYodlUllp+INlScqdhbXURbXURbXUT4cpmJS2VGZKp5QmSr+pcV1lMV1lMV1lA+HUXmi4v9kcR1lcR1lcR3lwxcqfpLKVDGpTCpTxY7KT6r4TovrKIvrKIvrKB/+onKyikllqtipmFSeUPlJi+soi+soi+so9gf/kMpU8YbKTsXJFtdRFtdRFtdRPryk8kbFpDJVTCrfSWWqmFR2Kp5QmSqmxXWUxXWUxXUUgdiomFSmiidUvlPFpPJGxaQyVeyo7FRMKtPiOsriOsriOsqnYlJ5QmWqmFT+pYonVKaKHZWpYlLZqZgW11EW11EW11E+fEHlCZWpYlKZKp5QeUNlqpgqdlTeUNlZXEdZXEdZXEexP9hQ+U4VOypTxaTyRMWkslMxqbxRsaMyLa6jLK6jLK6jCMQDFTsqU8WOylTxhMoTFTsqb1TsqOwsrqMsrqMsrqPYH2yoTBVPqEwVT6i8UTGpTBU7KjsVk8pOxc7iOsriOsriOsqHb6YyVZxM5YmKSWWqmFQmlZ3FdZTFdZTFdRSB2KiYVHYqJpWp4gmVnYodlaliUvlJFTsq0+I6yuI6yuI6yqfiiYonKnZUpoqdip9U8YTKVDGp7FRMi+soi+soi+soH5XfVLGjMlW8UTGpPKEyVeyovLG4jrK4jrK4jvLhLxXfSeWJikllqphUnqiYVHYqnqiYVJ5YXEdZXEdZXEf58AWVJyreUHmi4gmVHZU3VKaKJxbXURbXURbXUT78YxU7KpPKGxWTyk7FGypTxaQyLa6jLK6jLK6jfDhcxaTyRMVOxaTyk1SmimlxHWVxHWVxHeXDFyp+k8oTFW+oPKGyU/HG4jrK4jrK4jqKQPyiih2VqWJSmSomlZ2KHZUnKp5Q2VlcR1lcR1lcR/kPtMpV8KwL9iMAAAAASUVORK5CYII="
                alt=""
                style="width: 18mm; height: 18mm"
              />
            </td>
          </tr>
          <tr>
            <td>2RMPXYK9</td>
          </tr>
          <tr>
            <td>VLC - 10:48 16/12/2023</td>
          </tr>
        </table>

        <table style="width: 100%; position: relative; margin-bottom: 5mm">
          <tr>
            <td>K. Hàng: Đức - 0769995555</td>
          </tr>
          <tr>
            <td>N. Viên: Hà Hải Dũng</td>
          </tr>
        </table>

        <table
          class="table-border"
          style="width: 100%; position: relative; margin-bottom: 5mm"
        >
          <tr>
            <td colspan="3" class="text-center"><b>MUA HÀNG</b></td>
          </tr>
          <tr class="text-center">
            <td>Sản phẩm</td>
            <td style="width: 10%">SL</td>
            <td style="width: 30%">Nguyên giá</td>
          </tr>

          <tr>
            <td>Cáp PD 20W Wix C to L 2m</td>
            <td class="text-center">1</td>
            <td class="text-right">99000</td>
          </tr>
        </table>

        <table
          class="table-border text-right"
          style="width: 100%; position: relative; margin-bottom: 5mm"
        >
          <tr>
            <td style="width: 70%">Khuyến mại Sản phẩm:</td>
            <td>0</td>
          </tr>
          <tr>
            <td style="width: 70%">Giảm trừ giá trị hóa đơn:</td>
            <td>0</td>
          </tr>
          <tr>
            <td>Khuyến mại Hóa đơn:</td>
            <td>0</td>
          </tr>
          <tr>
            <td>Mã giảm giá:</td>
            <td>0</td>
          </tr>
          <tr>
            <td>Thanh toán:</td>
            <td>99000</td>
          </tr>
        </table>

        <table
          class="table-border text-right"
          style="width: 100%; position: relative; margin-bottom: 5mm"
        >
          <tr>
            <td
              rowspan="5"
              class="text-center"
              style="-webkit-transform: rotate(270deg); width: 20%"
            >
              Trong đó:
            </td>
            <td style="width: 50%">Chuyển Khoản:</td>
            <td>9000</td>
          </tr>
          <tr>
            <td>Tiền mặt:</td>
            <td>90000</td>
          </tr>
          <tr>
            <td>Trả thẻ:</td>
            <td>0</td>
          </tr>
          <tr>
            <td>Dùng điểm:</td>
            <td>0</td>
          </tr>
          <tr>
            <td>Trả lại:</td>
            <td>0</td>
          </tr>
        </table>

        <div style="margin-top: 16px; margin-bottom: 16px; text-align: center">
          Chúc Quý khách một ngày tốt lành
        </div>

        <div>
          Sản phẩm được đổi / đổi trả hàng trong 30 ngày trên hệ thống cửa hàng
          của Velas Center.
        </div>
        <div>Sản phẩm cần được giữ:</div>
        <div>a. Nguyên vẹn.</div>
        <div>b. Không bị Ám mùi.</div>
        <div>c. Không bị Ẩm ướt.</div>
        <div>d. Đủ Tem, Tag sản phẩm.</div>
      </div>
    </div>

    <v-btn @click="exportToPDF">Export to PDF</v-btn>
  </div>
</template>

<script>
// import html2pdf from "html2pdf.js";

export default {
  name: "Test",
  methods: {
    getDPI() {
      const inchElement = document.createElement("div");
      inchElement.style.width = "1in";
      document.body.appendChild(inchElement);
      const dpi = inchElement.offsetWidth;
      document.body.removeChild(inchElement);
      return dpi;
    },
    pxToMm(pxValue) {
      const dpi = this.getDPI();
      const mmInch = 25.4;
      const inchValue = pxValue / dpi;
      const mmValue = inchValue * mmInch;
      return mmValue;
    },
    exportToPDF() {
      /* const element = document.getElementById('pdf-content')
            const height = this.pxToMm(element.offsetHeight)
            console.log(height)
            const options = {
                filename: 'document.pdf',
                // image: { type: 'jpeg', quality: 0.98 },
                html2canvas: { scale: 1 },
                jsPDF: { unit: 'mm', format: [80, height] }
            }
            html2pdf().from(element).set(options).save() */

      var iframe =
        document.querySelector("#invoice-print") ||
        document.createElement("iframe");
      iframe.setAttribute("id", "invoice-print");
      document.body.appendChild(iframe);
      iframe.contentWindow.document.body.innerHTML =
        document.querySelector("#pdf-content").innerHTML;

      var printScript = document.createElement("script");
      printScript.innerHTML = `function setPrintStyles(pagesize) {
                                    var documentHeight = ${
                                      iframe.contentWindow.document.body
                                        .offsetHeight /
                                        2 +
                                      50
                                    };
                                    var css = \`@media print { @page { size: $\{pagesize} $\{documentHeight}px; } }\`,
                                        head = document.head || document.getElementsByTagName('head')[0],
                                        style = document.createElement('style');
                                    head.appendChild(style);
                                    style.type = 'text/css';
                                    style.appendChild(document.createTextNode(css));
                                }`;
      iframe.contentWindow.document.head.appendChild(printScript);

      var bodyScript = document.createElement("script");
      bodyScript.innerHTML = `fetch('${window.location.origin}/receipt.css')
                            .then(response => response.text())
                            .then(data => {
                                var head = document.head || document.getElementsByTagName('head')[0],
                                    style = document.createElement('style');
                                head.appendChild(style);
                                style.type = 'text/css';
                                style.appendChild(document.createTextNode(data));
                                setPrintStyles('80mm');
                                window.print();
                            });`;
      iframe.contentWindow.document.body.appendChild(bodyScript);
    },
  },
};
</script>

<style lang="scss">
@page {
  size: 80mm auto;
  width: 80mm;
  height: 100%;
  margin: 0;
}

#pdf-content {
  width: 80mm;
  height: auto;
  margin: 0;
  padding: 0;
  font-size: 3mm;

  .table-border {
    border-collapse: collapse;
  }

  .table-border,
  .table-border td,
  .table-border th {
    border: 0.3mm solid black;
    padding: 1mm;
  }

  .text-center {
    text-align: center;
  }

  .text-right {
    text-align: right;
  }
}
</style>
